import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Card from '../includes/Cards';
import UserPasswordForm from './form/user/UserPasswordForm';
import UserInfosForm from './form/user/UserInfosForm';
import GlobalParamForm from './form/user/GlobalParamForm';

const UserModifiedCard = ({ editCard, userToUpdate, getList }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [modal, setModal] = useState(false);

  const toggle = currentTab =>
    currentTab !== activeTab && setActiveTab(currentTab);

  const tabHeader = [
    {
      key: 1,
      title: 'Modifier les données utilisateur',
      component: (
        <UserInfosForm
          user={userToUpdate}
          getList={getList}
          setModal={setModal}
        />
      ),
    },
    {
      key: 2,
      title: 'Changer le mot de passe',
      component: (
        <UserPasswordForm
          user={userToUpdate}
          getList={getList}
          setModal={setModal}
        />
      ),
    },
    {
      key: 3,
      title: 'Modifier les paramètres des fonctions',
      component: (
        <GlobalParamForm
          user={userToUpdate}
          getList={getList}
          setModal={setModal}
        />
      ),
    },
  ];

  return (
    <Card>
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader>Alert</ModalHeader>
        <ModalBody>Modifications effectuées</ModalBody>
        <ModalFooter>
          <Button color="secondary" size="sm" onClick={() => setModal(false)}>
            Continuer
          </Button>
        </ModalFooter>
      </Modal>
      <div className="card-header">
        <ul className="nav nav-tabs card-header-tabs">
          {tabHeader.map(({ key, title }) => (
            <li key={key} className="nav-item">
              <button
                className={'nav-link ' + (activeTab === key ? 'active' : '')}
                onClick={() => toggle(key)}
              >
                {title}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="tab-content card-body">
        {tabHeader.map(({ key, component }) => (
          <div
            key={key}
            className={'tab-pane ' + (activeTab === key ? 'active' : '')}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                color="primary"
                style={{ float: 'right' }}
                size="sm"
                onClick={() => editCard(false)}
              >
                Quitter les modifications
              </Button>
            </div>
            {component}
          </div>
        ))}
      </div>
    </Card>
  );
};

export default UserModifiedCard;
