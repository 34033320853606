import React from 'react';

const CardTitle = ({ cardTitle, cardIcon }) => (
  <span>
    <i className={`${cardIcon} fa-fw`} />
    &nbsp; {cardTitle}
  </span>
);

export default CardTitle;
