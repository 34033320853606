import React, { useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Alert,
  Spinner,
} from 'reactstrap';

import './Login.css';
import Logo from '../../assets/img/primeradiant_logo_150x150.png';
import User from '../../services/User.service';
import { statusManager } from '../../utils/statusManager.utils';

const LoginView = () => {
  const history = useHistory();
  const [state, setState] = useState({ email: '', password: '' });
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInput = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let { email, password } = state;

    await User.login({ email, password })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.role === 'admin') {
            history.push('/admin/home');
          } else if (res.data.role === 'client') {
            history.push('/client/home');
          } else {
            setLoading(false);
            history.push('/');
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 500) {
          setLoading(false);
          setAlert({
            ...alert,
            showAlert: true,
            alertTitle: 'Une erreur est survenue',
            alertMessage: err.response.data.message,
          });
        } else {
          statusManager(err.response.status);
        }
      });
  };

  return (
    <Fragment>
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <form
              className="login100-form validate-form"
              onSubmit={handleSubmit}
            >
              <span className="login100-form-logo">
                <img src={Logo} alt="Company logo" />
              </span>
              <span className="login100-form-title">PrimeRadiant</span>
              {alert.showAlert && (
                <Alert
                  className="spacing"
                  style={{ textAlign: 'center' }}
                  color="danger"
                >
                  <h4>Identifiants incorrects</h4>
                </Alert>
              )}
              <InputGroup className="spacing">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="customInput">
                    <i className="fa fa-user" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  className="customInput"
                  placeholder="Email"
                  spellCheck="false"
                  name="email"
                  autoComplete="off"
                  value={state.email}
                  onChange={handleInput}
                />
              </InputGroup>
              <InputGroup className="spacing">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="customInput">
                    <i className="fas fa-lock" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="password"
                  className="customInput"
                  placeholder="Mot de passe"
                  spellCheck="false"
                  name="password"
                  autoComplete="off"
                  value={state.password}
                  onChange={handleInput}
                />
              </InputGroup>
              <div className="container-login100-form-btn spacing">
                {!loading ? (
                  <Button type="submit" size="lg">
                    Login
                  </Button>
                ) : (
                  <Spinner
                    style={{ width: '3rem', height: '3rem', color: 'white' }}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginView;
