import axios from 'axios';

import { API_FUNCTION_PATH } from '../config/api.config';

export default class Function {
  static async list(cancelToken = '') {
    return await axios.get(`${API_FUNCTION_PATH}all`, {
      cancelToken: cancelToken.token,
      withCredentials: true,
    });
  }
  static async create(func) {
    return await axios(`${API_FUNCTION_PATH}create`, {
      method: 'post',
      data: func,
      withCredentials: true,
    });
  }
  static async get(functionID) {
    return await axios.get(`${API_FUNCTION_PATH}find/${functionID}`, {
      withCredentials: true,
    });
  }
  static async update(functionID, body) {
    return await axios(`${API_FUNCTION_PATH}update/${functionID}`, {
      method: 'put',
      data: body,
      withCredentials: true,
    });
  }
  static async delete(functionID) {
    return await axios.delete(`${API_FUNCTION_PATH}delete/${functionID}`, {
      withCredentials: true,
    });
  }
}
