import React, { Fragment, useState, useEffect } from 'react';

import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Col,
} from 'reactstrap';

import { REG_PASSWORD } from '../../../../utils/regex.utils';
import FormErrors from './FormErrors';

const PasswordInput = ({ formState, setFormState }) => {
  const [inputState, setInputState] = useState({
    passwordType: 'password',
    iconType: 'far fa-eye-slash',
  });
  const [internalState, setInternalState] = useState({
    password: '',
    checkPassword: '',
  });
  const [inputErrors, setInputErrors] = useState({
    showFormError: false,
    password: '',
    checkPassword: '',
  });

  const handleInput = event => {
    const { name, value } = event.target;
    let tpmFormErrors = {};

    switch (name) {
      case 'password':
        if (value.length > 0) {
          tpmFormErrors.password = REG_PASSWORD.test(value)
            ? ''
            : 'Le mot de passe doit faire au minimum 8 caractères et doit comporter une lettre majuscules,minuscules, un chiffres et des symboles';
        } else {
          tpmFormErrors.password = '';
        }

        break;
      default:
        break;
    }

    setInternalState({
      ...internalState,
      [name]: value,
    });
    setFormState({ ...formState, [name]: value });
    checkFormError(tpmFormErrors);
  };

  const checkFormError = tpmFormErrors => {
    const { password } = tpmFormErrors;
    let showFormError = false;

    for (const value of Object.values(tpmFormErrors)) {
      if (value.length > 0) {
        showFormError = true;
        break;
      }
    }

    setInputErrors({ ...inputErrors, showFormError, password });
  };

  useEffect(() => {
    const { password, checkPassword } = internalState;
    setFormState(formState => ({ ...formState, password, checkPassword }));
  }, [internalState]);

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      formValid: !inputErrors.showFormError,
    }));
  }, [inputErrors]);

  const handleShowPassword = () => {
    let passwordType = '';
    let iconType = '';

    if (inputState.passwordType === 'password') {
      passwordType = 'text';
      iconType = 'far fa-eye';
    } else {
      passwordType = 'password';
      iconType = 'far fa-eye-slash';
    }

    setInputState({ ...inputState, passwordType, iconType });
  };

  return (
    <Fragment>
      {inputErrors.showFormError ? (
        <FormErrors formErrors={inputErrors} />
      ) : null}
      <Row>
        <Col>
          <Label htmlFor="password">Mot de passe</Label>
          <InputGroup id="password">
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                style={{ cursor: 'pointer' }}
                onClick={handleShowPassword}
              >
                <i className={inputState.iconType} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              value={internalState.password}
              name="password"
              type={inputState.passwordType}
              placeholder="Mot de passe"
              autoComplete="off"
              onChange={handleInput}
            />
          </InputGroup>
        </Col>
        <Col>
          <Label htmlFor="checkPassword">Vérification du mot de passe</Label>
          <InputGroup id="checkPassword">
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                style={{ cursor: 'pointer' }}
                onClick={handleShowPassword}
              >
                <i className={inputState.iconType} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              value={internalState.checkPassword}
              name="checkPassword"
              type={inputState.passwordType}
              placeholder="Vérification du mot de passe"
              autoComplete="off"
              onChange={handleInput}
            />
          </InputGroup>
        </Col>
      </Row>
    </Fragment>
  );
};

export default PasswordInput;
