import React from 'react';

const Card = ({ children }) => (
  <div
    className="card"
    style={{ width: '90%', margin: '20px auto 20px auto', maxWidth: 1400 }}
  >
    {children}
  </div>
);

export default Card;
