import React, { useState } from 'react';

export default function DropZone({ handleDrop }) {
  const [dragging, setDragging] = useState(false);

  const preventDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const dragOver = (event) => {
    preventDefault(event);
  };

  const dragEnter = (event) => {
    preventDefault(event);
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const dragLeave = (event) => {
    setDragging(false);
    preventDefault(event);
  };

  const onDrop = (event) => {
    preventDefault(event);
    setDragging(false);
    const file = event.dataTransfer.files;
    if (file.length) handleDrop(file);
  };

  return (
    <div
      style={{
        width: '100%',
        height: 200,
        border: '5px dashed',
        marginBottom: 15,
        marginTop: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: dragging ? 0.5 : 1,
      }}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={onDrop}
      onClick={() => {}}
    >
      Drag & drop
    </div>
  );
}
