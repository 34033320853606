import React, { Component } from 'react';

import JSONEditorReact from './JSONEditorReact';
import './JsonEditorComponent.css';
import { Button } from 'reactstrap';

const schema = {
  type: 'object',
  properties: {
    array: {
      type: 'array',
    },
    boolean: {
      type: 'boolean',
    },
    number: {
      type: 'number',
    },
  },
};

const modes = ['tree', 'code'];

class App extends Component {
  state = {
    schema,
    text: JSON.stringify(this.props.json, null, 2),
    json: {},
    mode: 'tree',
    errors: false,
  };

  componentDidMount = () => {
    this.setState({ json: this.props.paramsCunit });
  };

  render() {
    return (
      <div className="editor" style={{ width: '100%' }}>
        <div
          className="contents"
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <JSONEditorReact
            schema={this.state.schema}
            text={this.state.text}
            mode={this.state.mode}
            modes={modes}
            indentation={4}
            onChangeText={this.onChangeText}
            onModeChange={this.onModeChange}
            onValidationError={this.onValidationError}
          />
          <div className="code">
            <pre style={{ width: '400px' }}>
              <code>{this.state.text}</code>
            </pre>
          </div>
        </div>
        <br />
        <Button color="success" onClick={this.saveParam}>
          Sauvegarder
        </Button>
        <Button color="danger" onClick={this.quitParam}>
          Quitter
        </Button>
      </div>
    );
  }

  quitParam = () => {
    this.props.setFormErrors({ showFormError: false, paramsCunit: '' });
    this.props.quitParam({ showParamsForm: false });
  };

  saveParam = () => {
    if (!this.state.errors) {
      this.props.setFormErrors({
        showFormError: false,
        paramsCunit: '',
      });
      this.props.setFormState(JSON.parse(this.state.text));
    } else {
      this.props.setFormErrors({
        showFormError: true,
        paramsCunit:
          "Une ou plusieurs erreurs existe dans l'editeur, merci de les réctifier",
      });
    }
  };

  onChangeText = text => {
    this.setState({ text });
  };

  onModeChangeSelect = event => {
    this.setState({ mode: event.target.value });
  };

  onModeChange = mode => {
    this.setState({ mode });
  };

  onValidationError = errors => {
    errors.length
      ? this.setState({ errors: true })
      : this.setState({ errors: false });
  };
}

export default App;
