import React from 'react';
import { Row, Col, Alert } from 'reactstrap';

const FormErrors = ({ formErrors }) => (
  <Row>
    <Col>
      <Alert color="danger">
        <ul style={{ listStyle: 'none' }}>
          {Object.entries(formErrors).map(([key, value]) =>
            value.length > 0 ? <li key={key}>{value}</li> : null,
          )}
        </ul>
      </Alert>
    </Col>
  </Row>
);

export default FormErrors;
