import React, { Fragment } from 'react';
import VNavBar from '../../components/VNavBar/VNavBar';
import ExcelLoader from '../../components/ExcelLoader/ExcelLoader';

const MainView = () => {
  return (
    <Fragment>
      <VNavBar />
      <ExcelLoader />
    </Fragment>
  );
};

export default MainView;
