import React, { useState } from 'react';
import { CardHeader, Button, Alert } from 'reactstrap';

import Card from '../includes/Cards';
import CardTitle from '../includes/CardTitle';
import ExcelService from '../../services/Excel.service';
import { statusManager } from '../../utils/statusManager.utils';
import './ExcelLoader.css';
import DropZone from '../DropZone/DropZone';

const fileErrorSchema = {
  show: false,
  filename: '',
  sheet: '',
  error: '',
};

const ExcelLoader = () => {
  const [excel, setExcel] = useState({ file: null, value: '' });
  const [uploading, setUploading] = useState(false);
  const [fileError, setFileError] = useState(fileErrorSchema);
  const [fileUploaded, setFileUploaded] = useState(false);

  const reset = () => {
    setFileUploaded(false);
    setUploading(false);
    setFileError(fileErrorSchema);
  };

  const excelUpload = (event) => {
    reset();
    console.log(event.target.value);
    setExcel({
      file: event.target.files[0],
      value: event.target.value,
    });
  };

  const handleDrop = (file) => {
    reset();
    setExcel({
      file: file[0],
      value: '',
    });
  };

  const sendExcel = () => {
    if (excel.file !== null) {
      setFileError(fileErrorSchema);
      setUploading(true);
      const data = new FormData();
      data.append('file', excel.file);
      ExcelService.checkExcel(data)
        .then((res) => {
          console.log(res);
          if (res.data.result !== 200) {
            setFileError({
              show: true,
              filename: res.data.filename,
              sheet: res.data.result[0],
              error: res.data.result[1],
            });
          } else {
            setFileUploaded(true);
            setExcel({ file: null, value: '' });
          }
          setUploading(false);
        })
        .catch((err) => {
          setUploading(false);
          let error = '';
          if (err.response.status === 401 || err.response.status === 500) {
            error = 'Une erreur est survenue';
          } else if (err.response.status === 403) {
            error = err.response.data.message;
          } else {
            statusManager(err.response.status);
          }

          setUploading(false);
          setFileError({
            show: true,
            error,
          });
        });
    }
  };

  const deleteFile = () => {
    setExcel({
      ...excel,
      file: null,
      value: '',
    });
    setFileError(fileErrorSchema);
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle cardTitle={'Excel'} cardIcon="fa fa-file-excel" />
          {fileError.show && excel.file !== null && (
            <Alert style={{ marginTop: 25 }} color="danger">
              Fichier : {fileError.filename}, Page : {fileError.sheet} <br />
              Erreur : {fileError.error}
            </Alert>
          )}
          <Alert
            style={{ marginTop: 25 }}
            color="info"
            isOpen={fileUploaded}
            toggle={() => setFileUploaded(false)}
          >
            Le fichier ne comporte pas d'erreurs
          </Alert>
          <DropZone handleDrop={handleDrop} />
          <form style={{ float: 'right' }}>
            <label htmlFor="file" className="uploadFile">
              Choisir un fichier
            </label>
            <input
              id="file"
              type="file"
              name="file"
              value={excel.value}
              style={{ display: 'none' }}
              onChange={excelUpload}
            />
            {excel.file && (
              <span style={{ marginRight: 10 }}>
                {excel.file.name} &nbsp;{' '}
                <i
                  className={'fa fa-times'}
                  onClick={deleteFile}
                  style={{ cursor: 'pointer' }}
                />
              </span>
            )}

            <Button
              color="primary"
              size="sm"
              onClick={sendExcel}
              disabled={excel.file === null ? true : false}
            >
              {uploading ? <i className="fa fa-spinner fa-spin" /> : 'Upload'}
            </Button>
          </form>
        </CardHeader>
      </Card>
    </div>
  );
};

export default ExcelLoader;
