import React, { Component, Fragment } from 'react';
import VNavBar from '../../components/VNavBar/VNavBar';

class MainView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Fragment>
        <VNavBar props={this.props} />
      </Fragment>
    );
  }
}

export default MainView;
