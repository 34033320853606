import axios from 'axios';

import { API_EXCEL_PATH } from '../config/api.config';

class Excel {
  async checkExcel(data) {
    return await axios(`${API_EXCEL_PATH}`, {
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
      withCredentials: true,
    });
  }
}

export default new Excel();
