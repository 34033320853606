import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Button, Alert } from 'reactstrap';

import User from '../../../../services/User.service';
import './Form.css';
import GlobalParamTable from '../includes/GlobalParamTable/GlobalParamTable';
import FormErrors from '../includes/FormErrors';
import { statusManager } from '../../../../utils/statusManager.utils';

const GlobalParamForm = ({ user, getList, setModal }) => {
  let alertAnchor = useRef();

  const [newParamState, setNewParamState] = useState();
  const [globalParam, setGlobalParam] = useState();
  const [jsonEditorOpen, setJsonEditorOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({
    showFormError: false,
    paramsCunit: "JSON editeur est en cours d'utilisation",
  });
  const [alert, setAlert] = useState({
    showAlert: false,
    alertTitle: '',
    alertMessage: '',
  });

  useEffect(() => {
    setGlobalParam(user.paramsCunit);
  }, [user]);

  const handleSubmit = event => {
    event.preventDefault();
    if (jsonEditorOpen) {
      setFormErrors({ ...formErrors, showFormError: true });
    } else {
      setFormErrors({ ...formErrors, showFormError: false });

      User.update(user._id, { paramsCunit: newParamState })
        .then(() => {
          getList();
          setModal(true);
        })
        .catch(err => {
          if (err.response.status === 400) {
            setAlert({
              ...alert,
              showAlert: true,
              alertTitle: 'Une erreur est survenue',
              alertMessage: err.response.data.message,
            });
          } else {
            statusManager(err.response.status);
          }
        });
    }
  };

  return (
    <Fragment>
      {alert.showAlert && (
        <div ref={alertAnchor}>
          <Alert color="danger">
            <h4>{alert.alertTitle}</h4>
            <p>{alert.alertMessage}</p>
          </Alert>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        {formErrors.showFormError && <FormErrors formErrors={formErrors} />}
        <GlobalParamTable
          formState={globalParam}
          setFormState={setNewParamState}
          setJsonEditorOpen={setJsonEditorOpen}
        />
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="submit" style={{ float: 'right', marginTop: '15px' }}>
            Sauvegarder
          </Button>
        </div>
      </form>
    </Fragment>
  );
};
export default GlobalParamForm;
