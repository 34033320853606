import React, { useState, useEffect } from 'react';
import axios from 'axios';

import FunctionService from '../../services/Function.service';
import VNavBar from '../../components/VNavBar/VNavBar';
import ListCard from '../../components/admin/ListCard';
import FunctionAddEditCard from '../../components/admin/FunctionAddEditCard';
import { statusManager } from '../../utils/statusManager.utils';

const FunctionsViews = () => {
  const [list, setList] = useState([]);
  const [card, setCard] = useState(false);
  const [elToModify, setElToModify] = useState(null);

  const editCard = (show, id = '') => {
    id !== '' && setElToModify(list.filter(user => user._id === id)[0]);
    setCard(show);
  };

  const addCard = show => {
    setCard(show);
    setElToModify(null);
  };

  const getList = async cancelToken => {
    try {
      const promise = await FunctionService.list(cancelToken);
      setList(promise.data.list);
    } catch (err) {
      if (!axios.isCancel(err)) {
        statusManager(err.response.status);
      }
    }
  };

  useEffect(() => {
    let source = axios.CancelToken.source();
    getList(source);

    return () => source.cancel();
  }, []);

  return (
    <div>
      <VNavBar />
      <ListCard
        editCard={editCard}
        addCard={addCard}
        list={list}
        getList={getList}
        context="function"
      />
      {card && (
        <FunctionAddEditCard
          addCard={addCard}
          func={elToModify}
          getList={getList}
        />
      )}
    </div>
  );
};

export default FunctionsViews;
