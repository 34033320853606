import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Button, Alert } from 'reactstrap';

import User from '../../../../services/User.service';
import FormErrors from '../includes/FormErrors';
import PasswordInput from '../includes/PasswordInput';
import { statusManager } from '../../../../utils/statusManager.utils';

const UserPasswordForm = ({ user, getList, setModal }) => {
  const componentDidMount = useRef(true);
  let alertAnchor = useRef();

  const [passwordFormState, setPasswordFormState] = useState({
    password: '',
    checkPassword: '',
    formValid: false,
  });
  const [formErrors, setFormErrors] = useState({
    showFormError: false,
    password: '',
    checkPassword: '',
  });
  const [alert, setAlert] = useState({
    showAlert: false,
    alertTitle: '',
    alertMessage: '',
  });

  useEffect(() => {
    // componentDidMount ref variable to avoid useEffect launch when first rendering
    if (componentDidMount.current) {
      componentDidMount.current = false;
    } else {
      passwordFormState.formValid && !formErrors.showFormError
        ? updateUser()
        : console.log('invalid : ', formErrors);
    }
  }, [formErrors]);

  const updateUser = () => {
    User.update(user._id, { password: passwordFormState.password })
      .then(() => {
        getList();
        setModal(true);
      })
      .catch(err => {
        if (err.response.status === 400) {
          setAlert({
            ...alert,
            showAlert: true,
            alertTitle: 'Une erreur est survenue',
            alertMessage: err.response.data.message,
          });
        } else {
          statusManager(err.response.status);
        }
      });
  };

  const checkInput = tmpFormErrors => {
    let showFormError = false;

    for (const value of Object.values(tmpFormErrors)) {
      if (value.length > 0) {
        showFormError = true;
        break;
      }
    }

    const { password, checkPassword } = tmpFormErrors;

    setFormErrors({
      ...formErrors,
      password,
      checkPassword,
      showFormError,
    });
  };

  const formValid = () => {
    let tmpFormErrors = {
      password: '',
      checkPassword: '',
    };

    tmpFormErrors.password = !passwordFormState.password.length
      ? 'Le champ mot de passe est vide'
      : '';

    tmpFormErrors.checkPassword =
      !passwordFormState.checkPassword.length ||
      passwordFormState.checkPassword !== passwordFormState.password
        ? 'Le champ de vérification de mot de passe est incorrect'
        : '';

    checkInput(tmpFormErrors);
  };

  const handleSubmit = event => {
    event.preventDefault();
    formValid();
  };

  return (
    <Fragment>
      {alert.showAlert && (
        <div ref={alertAnchor}>
          <Alert color="danger">
            <h4>{alert.alertTitle}</h4>
            <p>{alert.alertMessage}</p>
          </Alert>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        {formErrors.showFormError ? (
          <FormErrors formErrors={formErrors} />
        ) : null}
        <PasswordInput
          formState={passwordFormState}
          setFormState={setPasswordFormState}
        />
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="submit" style={{ float: 'right', marginTop: '15px' }}>
            Sauvegarder
          </Button>
        </div>
      </form>
    </Fragment>
  );
};

export default UserPasswordForm;
