import React, { useState, useEffect } from 'react';
import axios from 'axios';

import VNavBar from '../../components/VNavBar/VNavBar';
import ListCard from '../../components/admin/ListCard';
import UserModifiedCard from '../../components/admin/UserModifiedCard';
import UserAddCard from '../../components/admin/UserAddCard';
import UserService from '../../services/User.service';
import { statusManager } from '../../utils/statusManager.utils';

const UserView = () => {
  const [list, setList] = useState([]);
  const [cards, setCards] = useState({ addCard: false, editCard: false });
  const [userToUpdate, setUserToUpdate] = useState({});

  const addCard = show => setCards({ addCard: show, editCard: false });

  const editCard = (show, id = '') => {
    id !== '' && setUserToUpdate(list.filter(user => user._id === id)[0]);
    setCards({ addCard: false, editCard: show });
  };

  const getList = async cancelToken => {
    try {
      const promise = await UserService.list(cancelToken);
      setList(promise.data.list);
    } catch (err) {
      if (!axios.isCancel(err)) {
        statusManager(err.response.status);
      }
    }
  };

  useEffect(() => {
    let source = axios.CancelToken.source();
    getList(source);

    return () => source.cancel();
  }, []);

  return (
    <div>
      <VNavBar />
      <ListCard
        editCard={editCard}
        addCard={addCard}
        list={list}
        getList={getList}
        context="user"
      />
      {cards.editCard && userToUpdate && (
        <UserModifiedCard
          editCard={editCard}
          userToUpdate={userToUpdate}
          getList={getList}
        />
      )}
      {cards.addCard && <UserAddCard addCard={addCard} getList={getList} />}
    </div>
  );
};

export default UserView;
