import history from '../routes/history';

export const statusManager = status => {
  switch (status) {
    case 401:
      history.push('/');
      break;

    case 404:
      history.push('/404');
      break;

    case 503:
      history.push('/service_unavailable');
      break;
    default:
      return;
  }
};
