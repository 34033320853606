import React, { useState, useRef, useEffect } from 'react';
import {
  Input,
  Label,
  Row,
  Col,
  Button,
  FormGroup,
  CustomInput,
  Alert,
} from 'reactstrap';

import Card from '../includes/Cards';
import FormErrors from './form/includes/FormErrors';
import Function from '../../services/Function.service';
import { statusManager } from '../../utils/statusManager.utils';

let schemaReqParam = {
  name: '',
  localisation: '',
  type: '',
  example: '',
};
let schemaReqResponse = {
  name: '',
  type: '',
};
let schemaReqExample = {
  input: '',
  output: '',
};
let schemaReqInfo = {
  interneName: '',
  displayedName: '',
  displayUrl: '',
  urlTypeRadio: '',
  description: '',
};

const URL_TYPES = ['GET', 'POST', 'PUT', 'DELETE'];

const FunctionAddEditCard = ({ addCard, func, getList }) => {
  let alertAnchor = useRef();
  let componentDidMount = useRef(true);

  const [reqInfo, setReqInfo] = useState(schemaReqInfo);
  const [requestParam, setRequestParam] = useState([schemaReqParam]);
  const [requestResponse, setRequestResponse] = useState([schemaReqResponse]);
  const [requestExample, setRequestExample] = useState([schemaReqExample]);
  const [formError, setFormError] = useState({
    showFormError: false,
    error: [],
  });
  const [alert, setAlert] = useState({
    showAlert: false,
    alertTitle: '',
    alertMessage: '',
  });

  useEffect(() => {
    if (componentDidMount.current) {
      componentDidMount.current = false;
    } else if (formError.showFormError) {
      windowScroll();
    }
  }, [formError]);

  useEffect(() => {
    setReqInfo(schemaReqInfo);
    setRequestParam([schemaReqParam]);
    setRequestResponse([schemaReqResponse]);
    setRequestExample([schemaReqExample]);

    if (func !== null) {
      setReqInfo({
        interneName: func.name,
        displayedName: func.nameToShow,
        urlTypeRadio: func.type,
        displayUrl: func.url,
        description: func.description,
      });
      setRequestParam(func.request_parameters);
      setRequestResponse(func.responses);
      setRequestExample(func.examples);
    }
  }, [func]);

  const addReq = (getter, setter, schema) => {
    setter([...getter, schema]);
  };

  const handleReq = (event, index, getter, setter) => {
    const { name, value } = event.target;
    let tmpArray = [...getter];
    tmpArray[index] = { ...tmpArray[index], [name]: value };
    setter(tmpArray);
  };
  const deleteReq = (index, getter, setter) => {
    let tmpArray = [...getter];
    tmpArray.splice(index, 1);
    setter(tmpArray);
  };

  const handleRadioButton = event => {
    setReqInfo({ ...reqInfo, urlTypeRadio: event.target.value });
  };

  const handleCustomInput = event => {
    let { name, value } = event.target;

    setReqInfo({ ...reqInfo, [name]: value });
  };

  const functionCreation = () => {
    let tmpParam = [...requestParam];
    let tmpResponses = [...requestResponse];
    let tmpExample = [...requestExample];

    // Filter empty data
    tmpParam = tmpParam.filter(
      el =>
        el.name.length ||
        el.localisation.length ||
        el.type.length ||
        el.type.example,
    );
    tmpResponses = tmpResponses.filter(el => el.name.length || el.type.length);
    tmpExample = tmpExample.filter(el => el.output.length || el.input.length);

    const newFunc = {
      name: reqInfo.interneName,
      nameToShow: reqInfo.displayedName,
      type: reqInfo.urlTypeRadio,
      url: reqInfo.displayUrl,
      description: reqInfo.description,
      request_parameters: tmpParam,
      responses: tmpResponses,
      examples: tmpExample,
    };

    const response = !func
      ? Function.create(newFunc)
      : Function.update(func._id, newFunc);

    response
      .then(res => {
        if (res.status === 200) {
          getList();
          addCard(false);
        }
      })
      .catch(err => {
        if (err.response.status === 400) {
          setAlert({
            ...alert,
            showAlert: true,
            alertTitle: 'Une erreur est survenue',
            alertMessage: err.response.data.message,
          });
          windowScroll();
        } else {
          statusManager(err.response.status);
        }
      });
  };

  const windowScroll = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: alertAnchor.current.offsetTop,
    });
  };

  const formValid = () => {
    let errorMessage = [];

    if (!reqInfo.interneName.length) {
      errorMessage.push('Le nom interne est obligatoire');
    }
    if (!reqInfo.displayedName.length) {
      errorMessage.push("Le nom d'affichage est obligatoire");
    }
    if (!reqInfo.displayUrl.length) {
      errorMessage.push("L'url est obligatoire");
    }

    if (errorMessage.length) {
      setFormError({ showFormError: true, error: errorMessage });
    } else {
      setFormError({ showFormError: false, error: errorMessage });
      functionCreation();
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    formValid();
  };

  return (
    <Card>
      <div className="card-body">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            color="primary"
            style={{ float: 'right' }}
            size="sm"
            onClick={() => addCard(false)}
          >
            Quitter l'ajout d'utilisateur
          </Button>
        </div>
        <br />
        {alert.showAlert && (
          <div ref={alertAnchor}>
            <Alert color="danger">
              <h4>{alert.alertTitle}</h4>
              <p>{alert.alertMessage}</p>
            </Alert>
          </div>
        )}
        {formError.showFormError && (
          <div ref={alertAnchor}>
            <FormErrors formErrors={formError.error} />
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="interneName">Nom interne</Label>
                <Input
                  type="text"
                  name="interneName"
                  placeholder="Nom interne"
                  onChange={handleCustomInput}
                  value={reqInfo.interneName}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="displayedName">Nom d'affichage</Label>
                <Input
                  type="text"
                  name="displayedName"
                  placeholder="Nom d'affichage"
                  onChange={handleCustomInput}
                  value={reqInfo.displayedName}
                />
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="displayUrl">URL</Label>
                <Input
                  type="text"
                  name="displayUrl"
                  placeholder="URL"
                  onChange={handleCustomInput}
                  value={reqInfo.displayUrl}
                />
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="urlType">URL Type</Label>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    width: 400,
                  }}
                >
                  {URL_TYPES.map((type, index) => (
                    <CustomInput
                      key={index}
                      type="radio"
                      id={`urlType${type}`}
                      name="urlTypeRadio"
                      label={type}
                      checked={reqInfo.urlTypeRadio === type}
                      onChange={handleRadioButton}
                      value={type}
                    />
                  ))}
                </div>
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Label htmlFor="description">Description</Label>
              <textarea
                rows="5"
                type="text"
                name="description"
                style={{
                  width: '100%',
                  boxShadow: '1px 1px 5px gray',
                  marginTop: 10,
                }}
                value={reqInfo.description}
                onChange={handleCustomInput}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <h4>Paramètres de requete</h4>

              <Button
                size="sm"
                color="primary"
                style={{ float: 'right' }}
                onClick={() =>
                  addReq(requestParam, setRequestParam, schemaReqParam)
                }
              >
                Add field
              </Button>
            </Col>
          </Row>

          {requestParam.map((req, index) => (
            <div key={index}>
              <br />
              <Button
                className="btn-icon"
                color="danger"
                size="sm"
                style={{ float: 'right' }}
                onClick={() => deleteReq(index, requestParam, setRequestParam)}
              >
                <i className="fa fa-trash-alt" />
              </Button>
              <Row>
                <br />
                <Col>
                  <FormGroup>
                    <Label>Nom</Label>
                    <Input
                      type="text"
                      name="name"
                      placeholder="Nom"
                      value={req.name}
                      onChange={e =>
                        handleReq(e, index, requestParam, setRequestParam)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Localisation</Label>
                    <Input
                      type="text"
                      name="localisation"
                      placeholder="Localisation"
                      value={req.localisation}
                      onChange={e =>
                        handleReq(e, index, requestParam, setRequestParam)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Type</Label>
                    <Input
                      type="text"
                      name="type"
                      placeholder="Type"
                      value={req.type}
                      onChange={e =>
                        handleReq(e, index, requestParam, setRequestParam)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <Label>Exemple</Label>
                  <textarea
                    rows="10"
                    name="example"
                    style={{
                      width: '100%',
                      boxShadow: '1px 1px 5px gray',
                    }}
                    value={req.example}
                    onChange={e =>
                      handleReq(e, index, requestParam, setRequestParam)
                    }
                  />
                </Col>
              </Row>
            </div>
          ))}
          <hr />
          <Row>
            <Col>
              <h4>Réponses</h4>

              <Button
                size="sm"
                color="primary"
                style={{ float: 'right' }}
                onClick={() =>
                  addReq(requestResponse, setRequestResponse, schemaReqResponse)
                }
              >
                Add field
              </Button>
            </Col>
          </Row>

          {requestResponse.map((req, index) => (
            <div key={index}>
              <br />
              <Button
                className="btn-icon"
                color="danger"
                size="sm"
                style={{ float: 'right' }}
                onClick={() =>
                  deleteReq(index, requestResponse, setRequestResponse)
                }
              >
                <i className="fa fa-trash-alt" />
              </Button>
              <Row>
                <br />
                <Col>
                  <FormGroup>
                    <Label>Nom</Label>
                    <Input
                      type="text"
                      name="name"
                      placeholder="Nom"
                      value={req.name}
                      onChange={e =>
                        handleReq(e, index, requestResponse, setRequestResponse)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Type</Label>
                    <Input
                      type="text"
                      name="type"
                      placeholder="Type"
                      value={req.type}
                      onChange={e =>
                        handleReq(e, index, requestResponse, setRequestResponse)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          ))}
          <hr />
          <Row>
            <Col>
              <h4>Exemples</h4>

              <Button
                size="sm"
                color="primary"
                style={{ float: 'right' }}
                onClick={() =>
                  addReq(requestExample, setRequestExample, schemaReqExample)
                }
              >
                Add field
              </Button>
            </Col>
          </Row>

          {requestExample.map((req, index) => (
            <div key={index}>
              <br />
              <Button
                className="btn-icon"
                color="danger"
                size="sm"
                style={{ float: 'right' }}
                onClick={() =>
                  deleteReq(index, requestExample, setRequestExample)
                }
              >
                <i className="fa fa-trash-alt" />
              </Button>
              <Row>
                <br />
                <Col>
                  <Label>Input</Label>
                  <textarea
                    rows="10"
                    name="input"
                    style={{
                      width: '100%',
                      boxShadow: '1px 1px 5px gray',
                    }}
                    value={req.input}
                    onChange={e =>
                      handleReq(e, index, requestExample, setRequestExample)
                    }
                  />
                </Col>
                <Col>
                  <Label>Output</Label>
                  <textarea
                    rows="10"
                    name="output"
                    style={{
                      width: '100%',
                      boxShadow: '1px 1px 5px gray',
                    }}
                    value={req.output}
                    onChange={e =>
                      handleReq(e, index, requestExample, setRequestExample)
                    }
                  />
                </Col>
              </Row>
            </div>
          ))}

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="submit" style={{ float: 'right', marginTop: '15px' }}>
              Sauvegarder
            </Button>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default FunctionAddEditCard;
