import React, { useState, useEffect, useRef, Fragment } from 'react';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Alert,
} from 'reactstrap';

import Function from '../../../../services/Function.service';
import User from '../../../../services/User.service';
import './Form.css';
import FunctionsListCheckBox from '../includes/FunctionListCheck/FunctionsListCheckBox';
import FormErrors from '../includes/FormErrors';
import { REG_EMAIL } from '../../../../utils/regex.utils';
import { statusManager } from '../../../../utils/statusManager.utils';

const UserInfoForm = ({ user, getList, setModal }) => {
  const componentDidMount = useRef(true);
  let alertAnchor = useRef();

  const [userInfoState, setUserInfoState] = useState({
    username: '',
    email: '',
    token: '',
  });
  const [formErrors, setFormErrors] = useState({
    showFormError: false,
    username: '',
    email: '',
  });
  const [functionListState, setFunctionListCheck] = useState({
    functionList: [],
    checkFunctionList: [],
  });
  const [alert, setAlert] = useState({
    showAlert: false,
    alertTitle: '',
    alertMessage: '',
  });

  useEffect(() => {
    getFunctionList();
  }, []);

  useEffect(() => {
    setUserInfoState(user);
    setFunctionListCheck({
      ...functionListState,
      checkFunctionList: user.available_functions,
    });
  }, [user]);

  useEffect(() => {
    // componentDidMount ref variable to avoid useEffect launch when first rendering
    if (componentDidMount.current) {
      componentDidMount.current = false;
    } else {
      !formErrors.showFormError
        ? updateUser()
        : console.log('invalid : ', formErrors);
    }
  }, [formErrors]);

  const updateUser = () => {
    const update = {
      username: userInfoState.username,
      email: userInfoState.email,
      available_functions: functionListState.checkFunctionList,
      token: userInfoState.token,
    };

    User.update(user._id, update)
      .then(() => {
        getList();
        setModal(true);
      })
      .catch(err => {
        if (err.response.status === 400) {
          setAlert({
            ...alert,
            showAlert: true,
            alertTitle: 'Une erreur est survenue',
            alertMessage: err.response.data.message,
          });
        } else {
          statusManager(err.response.status);
        }
      });
  };

  const getFunctionList = async () => {
    await Function.list()
      .then(res => {
        setFunctionListCheck({
          ...functionListState,
          functionList: res.data.list,
        });
      })
      .catch(err => {
        statusManager(err.response.status);
      });
  };

  const handleInput = event => {
    const { name, value } = event.target;
    setUserInfoState({ ...userInfoState, [name]: value });
  };

  const checkInput = tmpFormErrors => {
    let showFormError = false;

    for (const value of Object.values(tmpFormErrors)) {
      if (value.length > 0) {
        showFormError = true;
        break;
      }
    }

    const { username, email } = tmpFormErrors;

    setFormErrors({
      ...formErrors,
      email,
      username,
      showFormError,
    });
  };

  const formValid = () => {
    let tmpFormErrors = {
      username: '',
      email: '',
    };

    tmpFormErrors.username = !userInfoState.username.length
      ? 'Le champ user name est vide'
      : '';

    tmpFormErrors.email = !userInfoState.email.length
      ? 'Le champ email est vide'
      : '';

    if (tmpFormErrors.email === '') {
      tmpFormErrors.email = REG_EMAIL.test(userInfoState.email)
        ? ''
        : "L'email est incorrect";
    }

    checkInput(tmpFormErrors);
  };

  const handleSubmit = event => {
    event.preventDefault();

    formValid();
  };

  const onRefreshToken = () => {
    User.refresh_token(user._id)
      .then(res => {
        setUserInfoState({ ...userInfoState, token: '' });
        setTimeout(() => {
          setUserInfoState({ ...userInfoState, token: res.data.token });
        }, 500);
      })
      .catch(err => {
        statusManager(err.response.status);
      });
  };

  return (
    <Fragment>
      {alert.showAlert && (
        <div ref={alertAnchor}>
          <Alert color="danger">
            <h4>{alert.alertTitle}</h4>
            <p>{alert.alertMessage}</p>
          </Alert>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        {formErrors.showFormError && <FormErrors formErrors={formErrors} />}
        <Row>
          <Col>
            <Label htmlFor="userName">Username</Label>
            <InputGroup id="userName">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-user"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                value={userInfoState.username}
                name="username"
                type="text"
                placeholder="Username"
                onChange={handleInput}
              />
            </InputGroup>
          </Col>
          <Col>
            <Label htmlFor="email">Email</Label>
            <InputGroup id="email">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-envelope" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                value={userInfoState.email}
                name="email"
                type="text"
                placeholder="Email"
                onChange={handleInput}
              />
            </InputGroup>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Label htmlFor="token">Token</Label>
            <InputGroup id="token">
              <InputGroupAddon
                className="clickableIcon"
                onClick={onRefreshToken}
                addonType="prepend"
              >
                <InputGroupText>
                  <i className="fa fa-sync" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="Token"
                readOnly
                value={userInfoState.token}
              />
              <InputGroupAddon
                className="clickableIcon"
                onClick={() => {
                  navigator.clipboard.writeText(userInfoState.token);
                }}
                addonType="prepend"
                id="UncontrolledTooltip"
              >
                <InputGroupText>
                  <i className="fas fa-clipboard-list" />
                </InputGroupText>
                <UncontrolledTooltip
                  placement="top"
                  target="UncontrolledTooltip"
                >
                  Save to clipboard
                </UncontrolledTooltip>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
        <FunctionsListCheckBox
          formState={functionListState}
          setFormState={setFunctionListCheck}
        />
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="submit" style={{ float: 'right', marginTop: '15px' }}>
            Sauvegarder
          </Button>
        </div>
      </form>
    </Fragment>
  );
};

export default UserInfoForm;
