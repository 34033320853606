const api_url =
  process.env.NODE_ENV === 'production'
    ? 'https://backend.dashboard.primeradiant.fr'
    : 'http://localhost:4000';

const path = '/api/routes/';

export const API_FUNCTION_PATH = `${api_url}${path}function/`;
export const API_USER_PATH = `${api_url}${path}user/`;
export const API_EXCEL_PATH = `${api_url}${path}excel/`;
