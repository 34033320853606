import React, { useState, useEffect, useRef } from 'react';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Col,
  Button,
  Alert,
} from 'reactstrap';

import Function from '../../services/Function.service';
import User from '../../services/User.service';
import './form/user/Form.css';
import FunctionsListCheckBox from './form/includes/FunctionListCheck/FunctionsListCheckBox';
import GlobalParamTable from './form/includes/GlobalParamTable/GlobalParamTable';
import PasswordInput from './form/includes/PasswordInput';
import Card from '../includes/Cards';
import FormErrors from './form/includes/FormErrors';
import { REG_EMAIL } from '../../utils/regex.utils';
import { statusManager } from '../../utils/statusManager.utils';

const UserAddCard = ({ addCard, getList }) => {
  let componentDidMount = useRef(true);
  let alertAnchor = useRef();

  const [userFormState, setUserFormState] = useState({
    userName: '',
    email: '',
  });
  const [passwordFormState, setPasswordFormState] = useState({
    password: '',
    checkPassword: '',
    formValid: false,
  });
  const [functionListState, setFunctionListCheck] = useState({
    functionList: [],
    checkFunctionList: [],
  });
  const [paramsCunit, setParamsCunit] = useState();
  const [jsonEditorOpen, setJsonEditorOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({
    showFormError: false,
    userName: '',
    password: '',
    checkPassword: '',
    jsonEditor: '',
  });
  const [alert, setAlert] = useState({
    showAlert: false,
    alertTitle: '',
    alertMessage: '',
  });

  // Only when component mount thanks to the []
  useEffect(() => {
    getFunctionList();
  }, []);

  const getFunctionList = async () => {
    await Function.list()
      .then(res => {
        setFunctionListCheck({
          ...functionListState,
          functionList: res.data.list,
        });
      })
      .catch(err => {
        console.log(err);
        statusManager(err.response.status);
      });
  };

  const userHandleInput = event => {
    const { name, value } = event.target;

    setUserFormState({ ...userFormState, [name]: value });
  };

  const checkInput = tmpFormErrors => {
    let showFormError = false;

    for (const value of Object.values(tmpFormErrors)) {
      if (value.length > 0) {
        showFormError = true;
        break;
      }
    }

    const {
      password,
      userName,
      checkPassword,
      email,
      jsonEditor,
    } = tmpFormErrors;

    setFormErrors({
      ...formErrors,
      email,
      password,
      userName,
      checkPassword,
      showFormError,
      jsonEditor,
    });
  };

  const formValid = () => {
    let tmpFormErrors = {
      password: '',
      checkPassword: '',
      userName: '',
      email: '',
      jsonEditor: '',
    };

    tmpFormErrors.password = !passwordFormState.password.length
      ? 'Le champ mot de passe est vide'
      : '';

    tmpFormErrors.checkPassword =
      !passwordFormState.checkPassword.length ||
      passwordFormState.checkPassword !== passwordFormState.password
        ? 'Le champ de vérification de mot de passe est incorrect'
        : '';

    tmpFormErrors.userName = !userFormState.userName.length
      ? 'Le champ user name est vide'
      : '';

    tmpFormErrors.email = !userFormState.email.length
      ? 'Le champ email est vide'
      : '';

    if (tmpFormErrors.email === '') {
      tmpFormErrors.email = REG_EMAIL.test(userFormState.email)
        ? ''
        : "L'email est incorrect";
    }

    tmpFormErrors.jsonEditor = jsonEditorOpen
      ? "JSON editeur est en cours d'utilisation"
      : '';

    checkInput(tmpFormErrors);
  };

  const handleSubmit = event => {
    event.preventDefault();
    setAlert({
      ...alert,
      showAlert: false,
      alertTitle: '',
      alertMessage: '',
    });
    formValid();
  };

  useEffect(() => {
    // componentDidMount ref variable to avoid useEffect launch when first rendering
    if (componentDidMount.current) {
      componentDidMount.current = false;
    } else if (passwordFormState.formValid && !formErrors.showFormError) {
      userCreation();
    } else {
      windowScroll();
    }
  }, [formErrors]);

  const userCreation = () => {
    let user = {
      email: userFormState.email,
      username: userFormState.userName,
      password: passwordFormState.password,
      available_functions: functionListState.checkFunctionList,
      paramsCunit,
    };

    User.create(user)
      .then(res => {
        if (res.status === 200) {
          getList();
          addCard();
        }
      })
      .catch(err => {
        if (err.response.status === 400) {
          setAlert({
            ...alert,
            showAlert: true,
            alertTitle: 'Une erreur est survenue',
            alertMessage: err.response.data.message,
          });
          windowScroll();
        } else {
          statusManager(err.response.status);
        }
      });
  };

  const windowScroll = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: alertAnchor.current.offsetTop,
    });
  };

  return (
    <Card>
      <div className="card-body">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            color="primary"
            style={{ float: 'right' }}
            size="sm"
            onClick={() => addCard(false)}
          >
            Quitter l'ajout d'utilisateur
          </Button>
        </div>
        <br />
        {alert.showAlert && (
          <div ref={alertAnchor}>
            <Alert color="danger">
              <h4>{alert.alertTitle}</h4>
              <p>{alert.alertMessage}</p>
            </Alert>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          {formErrors.showFormError && (
            <div ref={alertAnchor}>
              <FormErrors formErrors={formErrors} />
            </div>
          )}
          <Row>
            <Col>
              <Label htmlFor="userName">Username</Label>
              <InputGroup id="userName">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-user"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  value={userFormState.userName}
                  name="userName"
                  type="text"
                  placeholder="Username"
                  onChange={userHandleInput}
                />
              </InputGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Label htmlFor="email">Email</Label>
              <InputGroup id="email">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-envelope" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  value={userFormState.email}
                  name="email"
                  type="text"
                  placeholder="Email"
                  onChange={userHandleInput}
                />
              </InputGroup>
            </Col>
          </Row>
          <br />
          <PasswordInput
            formState={passwordFormState}
            setFormState={setPasswordFormState}
          />
          <br />
          <FunctionsListCheckBox
            formState={functionListState}
            setFormState={setFunctionListCheck}
          />
          <br />
          <GlobalParamTable
            formState={paramsCunit}
            setFormState={setParamsCunit}
            setJsonEditorOpen={setJsonEditorOpen}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="submit" style={{ float: 'right', marginTop: '15px' }}>
              Sauvegarder
            </Button>
          </div>
        </form>
      </div>
    </Card>
  );
};
export default UserAddCard;
