import React from 'react';
import { Row, Col } from 'reactstrap';

import './FunctionListCheck.css';

const FunctionListCheck = ({ formState, setFormState }) => {
  const addFunctionToUserList = (event, func) => {
    if (event.target.checked) {
      setFormState(prevFormState => ({
        ...prevFormState,
        checkFunctionList: [...prevFormState.checkFunctionList, func._id],
      }));
    } else {
      const checkFunctionList = formState.checkFunctionList.filter(
        el => el !== func._id,
      );
      setFormState(prevFormState => ({
        ...prevFormState,
        checkFunctionList,
      }));
    }
  };
  return (
    <Row>
      <Col>
        <div style={{ marginTop: '15px' }}>
          Fonctions
          <div className="checkboxContainer">
            {formState.functionList.length > 0 ? (
              formState.functionList.map(el => {
                return (
                  <label key={el._id}>
                    <input
                      checked={formState.checkFunctionList.includes(el._id)}
                      type="checkbox"
                      className="option-input checkbox"
                      onChange={event => addFunctionToUserList(event, el)}
                    />
                    {el.nameToShow}
                  </label>
                );
              })
            ) : (
              <div>Aucune fonction trouvé</div>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default FunctionListCheck;
