import React, { useEffect } from 'react';
import { Router, Route } from 'react-router-dom';

import history from './history';
import LoginView from '../views/Auth/LoginView';
import AdminMainView from '../views/admin/Mainview';
import UserView from '../views/admin/UserView';
import ClientMainView from '../views/client/Mainview';
import FunctionsView from '../views/admin/FunctionsView';
import NotFoundView from '../views/NotFoundView';
import ServiceUnavailable from '../views/ServiceUnavailable';
import { checkCredentials } from '../middleware/credentials.middleware';

const ADMIN_PATH = [
  '/admin/home',
  '/admin/users',
  '/admin/functions',
  '/admin/sheets',
  '/admin/data',
  '/admin/stats',
];

const CLIENT_PATH = ['/client/home'];

const NO_CHECK_PATH = ['/'];

const Routes = () => {
  useEffect(() => {
    checkCredentials(NO_CHECK_PATH, ADMIN_PATH, CLIENT_PATH);
    history.listen(() =>
      checkCredentials(NO_CHECK_PATH, ADMIN_PATH, CLIENT_PATH),
    );
  }, []);

  return (
    <Router history={history}>
      <Route exact path="/" component={LoginView} />
      <Route exact path="/admin/home" component={AdminMainView} />
      <Route exact path="/admin/users" component={UserView} />
      <Route exact path="/admin/functions" component={FunctionsView} />
      <Route exact path="/admin/sheets" component={AdminMainView} />
      <Route exact path="/admin/data" component={AdminMainView} />
      <Route exact path="/admin/stats" component={AdminMainView} />
      <Route exact path="/client/home" component={ClientMainView} />
      <Route exact path="/service_unavailable" component={ServiceUnavailable} />
      <Route exact path="/404" component={NotFoundView} />
    </Router>
  );
};

export default Routes;
