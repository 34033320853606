import React from 'react';
import { Table, Button } from 'reactstrap';
import { statusManager } from '../../utils/statusManager.utils';
import User from '../../services/User.service';
import Function from '../../services/Function.service';

const ListTable = ({ editCard, thead, tbody, context, getList }) => {
  const deleteUser = async id => {
    editCard(false);
    const confirmID = prompt("Confirmer l'ID à supprimer :", '');

    if (confirmID === id) {
      try {
        context === 'user' ? await User.delete(id) : await Function.delete(id);
        getList();
      } catch (err) {
        statusManager(err.response.status);
      }
    }
  };

  return (
    <Table responsive>
      <thead>
        <tr>
          {thead.map(({ key, name }) => (
            <th className="text-center" key={key}>
              {name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {context === 'user'
          ? tbody.length > 0 &&
            tbody.map(el => {
              return (
                el.role !== parseInt(process.env.REACT_APP_ADMIN_ROLE) && (
                  <tr key={el._id}>
                    <td className="text-center">{el._id}</td>
                    <td className="text-center">{el.email}</td>
                    <td className="text-center">{el.username}</td>
                    <td className="text-center">
                      {el.available_functions !== undefined &&
                        el.available_functions.length > 0 &&
                        el.available_functions.map((val, index) => (
                          <span key={index}>
                            {val} <br />
                          </span>
                        ))}
                    </td>
                    <td
                      className="text-center"
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <div style={{ width: 200 }}>{el.token}</div>
                    </td>
                    <td className="text-center">{el.createdAt}</td>
                    <td className="text-center">
                      <Button
                        className="btn-icon"
                        color="success"
                        size="sm"
                        onClick={() => editCard(true, el._id)}
                      >
                        <i className="fa fa-edit"></i>
                      </Button>
                      <Button
                        className="btn-icon"
                        color="danger"
                        size="sm"
                        onClick={() => deleteUser(el._id, el.token)}
                      >
                        <i className="fa fa-trash-alt" />
                      </Button>
                    </td>
                  </tr>
                )
              );
            })
          : tbody.length > 0 &&
            tbody.map(el => (
              <tr key={el._id}>
                <td className="text-center">{el._id}</td>
                <td className="text-center">{el.name}</td>
                <td className="text-center">{el.nameToShow}</td>
                <td className="text-center">{el.url}</td>
                <td className="text-center">{el.type}</td>
                <td className="text-center">
                  <div style={{ width: 200 }}> {el.description}</div>
                </td>
                <td className="text-center">
                  <Button
                    className="btn-icon"
                    color="success"
                    size="sm"
                    onClick={() => editCard(true, el._id)}
                  >
                    <i className="fa fa-edit"></i>
                  </Button>
                  <Button
                    className="btn-icon"
                    color="danger"
                    size="sm"
                    onClick={() => deleteUser(el._id, el.token)}
                  >
                    <i className="fa fa-trash-alt" />
                  </Button>
                </td>
              </tr>
            ))}
      </tbody>
    </Table>
  );
};

export default ListTable;
