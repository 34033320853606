import React from 'react';
import { CardHeader, CardBody, Button } from 'reactstrap';

import Card from '../includes/Cards';
import CardTitle from '../includes/CardTitle';
import ListTable from './ListTable';

const ListCard = ({ editCard, addCard, list, context, getList }) => {
  const USER_THEAD = [
    { key: 1, name: 'ID' },
    { key: 2, name: 'Email' },
    { key: 3, name: 'Username' },
    { key: 4, name: 'Fonctions' },
    { key: 5, name: 'Token' },
    { key: 6, name: 'Date de création' },
    { key: 7, name: 'Actions' },
  ];
  const FUNCTION_THEAD = [
    { key: 1, name: 'ID' },
    { key: 2, name: 'Nom' },
    { key: 3, name: "Nom d'affichage" },
    { key: 4, name: 'URL' },
    { key: 5, name: 'Type' },
    { key: 6, name: 'Description' },
    { key: 7, name: 'Actions' },
  ];

  let cardTitle = '';
  let buttonTitle = '';
  let thead = [];
  let cardIcon = '';

  if (context === 'user') {
    cardTitle = 'Utilisateurs';
    buttonTitle = 'Ajouter un utilisateur';
    thead = USER_THEAD;
    cardIcon = 'fa fa-user';
  } else if (context === 'function') {
    cardTitle = 'Fonctions';
    buttonTitle = 'Ajouter une fonction';
    thead = FUNCTION_THEAD;
    cardIcon = 'fa fa-cogs';
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle cardTitle={cardTitle} cardIcon={cardIcon} />
        <Button
          color="primary"
          size="sm"
          style={{ float: 'right' }}
          onClick={() => addCard(true)}
        >
          {buttonTitle}
        </Button>
      </CardHeader>
      <CardBody>
        <ListTable
          thead={thead}
          tbody={list}
          context={context}
          editCard={editCard}
          getList={getList}
        />
      </CardBody>
    </Card>
  );
};

export default ListCard;
