import axios from 'axios';

import { API_USER_PATH } from '../config/api.config';

export default class User {
  static async create(user) {
    return await axios(`${API_USER_PATH}add`, {
      method: 'post',
      data: user,
      withCredentials: true,
    });
  }
  static async get(userID) {
    return await axios.get(`${API_USER_PATH}find/${userID}`, {
      withCredentials: true,
    });
  }
  static async list(cancelToken = '') {
    return await axios.get(`${API_USER_PATH}list`, {
      cancelToken: cancelToken.token,
      withCredentials: true,
    });
  }

  static async update(userID, user) {
    return await axios(`${API_USER_PATH}updateInfos/${userID}`, {
      method: 'put',
      data: user,
      withCredentials: true,
    });
  }
  static async delete(userID) {
    return await axios.delete(`${API_USER_PATH}delete/${userID}`, {
      withCredentials: true,
    });
  }
  static async login(body) {
    return await axios(`${API_USER_PATH}login`, {
      method: 'post',
      data: body,
      withCredentials: true,
    });
  }
  static async refresh_token(userID) {
    return await axios.get(`${API_USER_PATH}refresh_token/${userID}`, {
      withCredentials: true,
    });
  }
  static async getRole() {
    return await axios.get(`${API_USER_PATH}role`, { withCredentials: true });
  }
}
