import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';

import './GlobalParamTable.css';
import FormErrors from '../FormErrors';
import JsonEditorComponent from '../../../../JSONEditor/JsonEditorComponent';

const GlobalParamTable = ({ formState, setFormState, ...props }) => {
  const [state, setState] = useState({
    showParamsForm: false,
  });
  const [paramsCunit, setParamsCunit] = useState({});
  const [formErrors, setFormErrors] = useState({
    showFormError: false,
    paramsCunit: '',
  });

  useEffect(() => {
    if (formState) {
      setParamsCunit(formState);
    }
  }, [formState]);

  useEffect(() => {
    setState({ showParamsForm: false });
    setFormState(paramsCunit);
  }, [paramsCunit]);

  useEffect(() => {
    props.setJsonEditorOpen && props.setJsonEditorOpen(state.showParamsForm);
  }, [state]);

  return (
    <Fragment>
      <p>Paramètres</p>
      {formErrors.showFormError && <FormErrors formErrors={formErrors} />}

      {state.showParamsForm && (
        <JsonEditorComponent
          formState={paramsCunit}
          setFormState={setParamsCunit}
          json={paramsCunit}
          quitParam={setState}
          setFormErrors={setFormErrors}
        />
      )}
      {!state.showParamsForm && (
        <Row>
          <Col>
            <textarea
              readOnly
              rows="10"
              style={{
                width: '100%',
                boxShadow: '1px 1px 5px gray',
                marginTop: 10,
              }}
              value={JSON.stringify(paramsCunit)}
            />

            <Button
              color="primary"
              style={{ marginTop: '15px' }}
              onClick={() =>
                setState(prevState => ({
                  ...prevState,
                  showParamsForm: !prevState.showParamsForm,
                }))
              }
            >
              Ajouter des paramètres
            </Button>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default GlobalParamTable;
