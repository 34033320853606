import Cookies from 'js-cookie';

import history from '../routes/history';
import UserService from '../services/User.service';

export function checkCredentials(NO_CHECK_PATH, ADMIN_PATH, CLIENT_PATH) {
  if (!NO_CHECK_PATH.includes(history.location.pathname)) {
    Cookies.get('sessionCookie') !== null
      ? redirection(ADMIN_PATH, CLIENT_PATH)
      : history.push('/');
  }
}

const redirection = (ADMIN_PATH, CLIENT_PATH) => {
  UserService.getRole()
    .then((res) => {
      const role = res.data.role;

      if (ADMIN_PATH.includes(history.location.pathname)) {
        if (role !== 'admin') {
          Cookies.remove('session-cookie');
          history.push('/');
        }
      } else if (CLIENT_PATH.includes(history.location.pathname)) {
        if (role !== 'client') {
          Cookies.remove('session-cookie');
          history.push('/');
        }
      } else {
        Cookies.remove('session-cookie');
        history.push('/');
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        Cookies.remove('session-cookie');
        history.push('/');
      }
    });
};
