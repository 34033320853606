// 8 to unlimited character, minimum one number, one uppercase & one lowercase, accepts special character
export const REG_PASSWORD = /^((?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,})$/;

// info part contains upper & lower case, digits, characters, the domain name letters, digits, hyphens, dots
export const REG_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

//
export const REG_URL_ADMIN_USER = /admin\/users/;

//
export const REG_URL_ADMIN_FUNCTION = /admin\/functions/;
